<template>
  <v-card class="add-to-price-list-card">
    <v-card-title>
      {{ $t("priceList.addToPriceListDialog.title") }}
    </v-card-title>
    <v-card-text>
      <template
        v-if="priceListProposalList && priceListProposalList.length > 0"
      >
        {{ $t("priceList.addToPriceListDialog.selectPriceList") }}
        <!-- :item-text="[priceList.name, priceList.fromDate, priceList.toDate]" -->
        <v-select
          :items="priceListProposalList"
          :item-text="
            item =>
              `${item.priceList.name}: da ${$dayjs(
                item.priceList.fromDate
              ).format('DD-MM-YYYY')} a ${$dayjs(item.priceList.toDate).format(
                'DD-MM-YYYY'
              )}`
          "
          return-object
          v-model="selectedPriceList"
          :label="$t('priceList.addToPriceListDialog.priceList')"
          dense
          outlined
        />

        <span class="d-block mt-2">
          {{ $t("priceList.addToPriceListDialog.explenationPromo") }}<br />
          <strong>
            {{ $t("priceList.addToPriceListDialog.productPrice")
            }}{{ $n(product.priceDisplay, "currency") }}
          </strong>
        </span>
        <div class="d-flex align-center justify-space-between mt-3">
          <v-text-field
            v-model="newPromoPrice"
            hide-details
            outlined
            dense
            @focus="$event.target.select()"
            :rules="isFloatingNumberRules"
            :label="$t('priceList.addToPriceListDialog.priceFlat')"
          ></v-text-field>
          <v-btn
            color="primary"
            class="add-product-price-btn ml-1"
            depressed
            :disabled="!selectedPriceList || !newPromoPrice"
            @click="addProductToPriceListProposal"
          >
            {{ $t("common.confirm") }}
          </v-btn>
        </div>
        <!-- <div class="d-flex align-center justify-space-between mt-3">
          <v-text-field
            v-model="newPercentPrice"
            :rules="isFloatingNumberRules"
            hide-details
            class="w-40"
            outlined
            dense
            :label="$t('priceList.addToPriceListDialog.pricePercent')"
          ></v-text-field>
          <span>
            {{ $t("priceList.addToPriceListDialog.calculatedPrice")
            }}{{
              $n(
                parseFloat(
                  (product.price * (100 - newPercentPrice)) / 100
                ).toFixed(2),
                "currency"
              )
            }}
          </span>
          <v-btn
            color="primary"
            depressed
            :disabled="!selectedPriceList || !newPercentPrice"
            @click="addProductToPriceList"
          >
            {{ $t("common.confirm") }}
          </v-btn>
        </div> -->
      </template>
      <template v-else-if="loaded">
        <p>{{ $t("priceList.addToPriceListDialog.noPriceList") }}</p>
        <v-btn color="primary" depressed :to="{ name: 'PriceListList' }">
          {{ $t("priceList.addToPriceListDialog.createPriceListBtn") }}
        </v-btn>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined depressed @click="abort">
        {{ $t("common.abort") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.add-to-price-list-card {
  .w-40 {
    max-width: 40%;
  }
  .add-product-price-btn.v-size--default.v-btn {
    height: 40px;
    padding-left: 8px;
  }
}
</style>
<script>
import priceListProposalService from "~/service/priceListProposalService";

import { isFloatingNumber } from "~/validator/validationRules";

export default {
  name: "addToPriceListDialog",
  props: {
    product: { type: Object, required: true }
  },
  data() {
    return {
      errorMessage: {},
      selectedPriceList: undefined,
      newPromoPrice: undefined,
      newPercentPrice: 0,
      isFloatingNumberRules: [isFloatingNumber()],
      priceListProposalList: null,
      loaded: false
    };
  },
  computed: {
    confirmBtnEnabled() {
      return (
        this.selectedPriceList &&
        (this.newPromoPrice > 0 || this.newPercentPrice > 0)
      );
    }
  },
  methods: {
    async fetchPriceListProposalList() {
      this.loaded = false;
      // getList only with status -1
      const result = await priceListProposalService.getList(-1);
      if (result.response?.status == 0 && result.data?.priceListProposal) {
        this.priceListProposalList = result.data.priceListProposal;
        if (this.priceListProposalList.length == 1) {
          this.selectedPriceList = this.priceListProposalList[0];
        }
      } else if (result.response) {
        this.priceListProposalList = [];
        this.errorMessage = result.response;
      }
      this.loaded = true;
    },
    async addProductToPriceListProposal() {
      const result = await priceListProposalService.addProductPrice(
        this.product.productId,
        this.selectedPriceList.priceListProposalId,
        parseFloat(this.newPromoPrice.replace(",", ".")),
        this.product.priceId
      );
      if (result.response?.status == 0) {
        this.$emit("submit", true);
      }
    },
    abort() {
      this.$emit("submit", false);
    }
  },
  mounted() {
    // this.newPromoPrice = this.product.priceDisplay
    //   .toFixed(2)
    //   .toString()
    //   .replace(".", ",");
    this.fetchPriceListProposalList();
  }
};
</script>
